<template>
  <el-card>
    <div slot="header">
      <h3>我的账户</h3>
    </div>
    <el-tabs v-model="tabActive">
      <el-tab-pane label="支出明细" name="first">
		  <div class="body">
		    <el-row>
		      <el-col :span="18">
		        <el-form :inline="true" :model="query">
		          <el-form-item label="时间段查询：">
		            <el-date-picker
		                v-model="query.dateRange"
		                type="daterange"
		                range-separator="至"
		                start-placeholder="开始日期"
		                end-placeholder="结束日期">
		            </el-date-picker>
		          </el-form-item>
		          <el-form-item>
		            <el-button type="primary" icon="el-icon-search">查询</el-button>
		          </el-form-item>
		        </el-form>
		      </el-col>
		      <el-col :span="4">
		        <h4>账户余额：2800 元</h4>
		      </el-col>
		      <el-col :span="2">
		        <el-button class="cashout" type="primary">提现</el-button>
		      </el-col>
		    </el-row>
		  
		  
		    <el-table show-summary :data="rows">
		      <el-table-column
		          prop="id"
		          label="id"
		          width="150">
		      </el-table-column>
			  
			  <el-table-column
			      prop="type"
			      label="消费类型"
			      width="120">
			  </el-table-column>
			  
		<!--      <el-table-column
		          prop="uname"
		          label="用户"
		          width="130">
		      </el-table-column> -->
		      <el-table-column
		          prop="price"
		          label="费用"
		          width="120">
		      </el-table-column>
		      <el-table-column
		          prop="num"
		          label="数量"
		          width="120">
		      </el-table-column>
		    <!--  <el-table-column
		          prop="money2"
		          label="优惠券"
		          width="150">
		      </el-table-column> -->
		      <el-table-column
		          prop="price"
		          label="实际支付"
		          width="120">
		      </el-table-column>
		   
		      <el-table-column
		          prop="create_time"
		          label="时间"
		          width="190">
		      </el-table-column>
		 <!--     <el-table-column
		          prop="sum_price"
		          label="支出统计"
		          width="150">
		      </el-table-column> -->
		  
		    </el-table>
		  
		<!-- 	    <el-pagination
					background
					layout="prev, pager, next"
					:total="1000">
				</el-pagination> -->
		  </div>
		  
	  </el-tab-pane>
      <el-tab-pane label="收入明细" name="second"></el-tab-pane>
      <el-tab-pane label="提现记录" name="third"></el-tab-pane>
    </el-tabs>

   
  </el-card>
</template>

<script>

  import axios from "axios"; 
  export default {
    name: "WalletIndex",
    data() {
      return {
        tabActive: "first",
        query: {},
        rows: []
      }
    },
	created(){  
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid')); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getPayLog',params)
		.then((response) => {   
			this.rows = response.data.data; 
					  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
	}
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    .body {
      h4 {
        margin: 0;
        padding: 10px 0;
      }

      .cashout {
        margin-top: 3px;
        width: 100%;
      }

      .el-table {
        width: 100%;
      }

      .el-pagination {
        text-align: center;
        margin-top: 20px;
      }


    }
  }
</style>
